import styled from 'styled-components';

const Section = styled.section`
  width: 100%;

  box-sizing: border-box;
  padding-left: 32px;
  padding-right: 32px;

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    padding-left: 16px;
    padding-right: 16px;
  }

  position: relative;
`;

export default Section;
