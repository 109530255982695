import React from 'react';
import styled from 'styled-components';

import logo from '../images/logo.svg';

const Title = styled.h1`
  font-size: 80px;
  line-height: 0.75;
  text-transform: none !important;

  .by {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: italic;

    font-size: 40px;
  }

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    font-size: 30px !important;
    line-height: 1;

    img {
      width: 45px;
      height: 18px;
    }

    .by {
      font-size: 15px;
    }
  }
`;

const Logo = () => (
  <img
    src={logo}
    width={90}
    height={36}
    alt="IRON"
    style={{ verticalAlign: '-6px' }}
  />
);

const RacehorseTitle = () => {
  return (
    <Title>
      Racehorse NFT<span className="by"> by </span>
      <Logo />
    </Title>
  );
};

export default RacehorseTitle;
