import styled from 'styled-components';

import { AutoLayout } from '@digigear/elements';

const Content = styled(AutoLayout).attrs({
  direction: 'column',
})`
  max-width: ${props => props.theme.dimensions.pageWidth};

  margin-left: auto;
  margin-right: auto;

  box-sizing: border-box;
  padding-top: 100px;
  padding-bottom: 100px;

  height: 100%;

  position: relative;

  h1 {
    text-transform: uppercase;
    letter-spacing: 2px;
  }

  @media screen and (max-width: ${props => props.theme.dimensions.breakpoint}) {
    h1 {
      font-size: 36px;
    }
  }
`;

export default Content;
