import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { AutoLayout, ResponsiveLayout } from '@digigear/elements';
import { useLocation } from '@reach/router';
import { useWeb3React } from '@web3-react/core';

import Button from '../components/Button';
import Content from '../components/Content';
import Input from '../components/Input';
import Layout from '../components/Layout';
import RacehorseTitle from '../components/RacehorseTitle';
import Section from '../components/Section';
import Seo from '../components/Seo';
import {
  GoldTierCard,
  PlatinumTierCard,
  SilverTierCard,
} from '../components/TiersSection';
import WalletButton from '../components/WalletButton';
import config from '../config';

const StyledForm = styled.form`
  color: #caefff;
  margin-top: 80px;
`;

const WhitelistRequestForm = ({ tier }) => {
  const [name, setName] = useState('');
  const [count, setCount] = useState(1);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { active, account } = useWeb3React();

  const handleSubmit = async event => {
    event.preventDefault();
    const token = searchParams.get('token');
    const queryParam = new URLSearchParams({ token: token });
    const body = JSON.stringify({
      address: account,
      name,
      tier: count,
    });

    console.log('WhitelistRequestForm handleSubmit', body);

    const response = await fetch(
      `${config.backendUrl}/whitelist/submitWhiteListRequest?${queryParam}`,
      {
        method: 'POST',
        body: body,
        headers: {
          'content-type': 'application/json',
        },
      }
    );
    const response_body = await response.json();
    console.log(
      'WhitelistRequestForm handleSubmit response_body',
      response_body
    );

    if (response_body.success) {
      toast.success(<p> Success, you are in whitelist now! </p>, {
        theme: 'colored',
        autoClose: true,
        position: toast.POSITION.TOP_RIGHT,
        closeOnClick: false,
      });
    } else {
      toast.warn(<p> Fail to submit whitelist request </p>, {
        theme: 'colored',
        autoClose: true,
        position: toast.POSITION.TOP_RIGHT,
        closeOnClick: false,
      });
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <ResponsiveLayout gap={20}>
        <Input
          type="text"
          placeholder="YOUR NAME"
          value={name}
          onChange={e => setName(e.target.value)}
          style={{ width: 480, maxWidth: '90vw' }}
        />
        {tier > 1 && (
          <>
            <label style={{ alignSelf: 'center' }}>COUNT:</label>
            <Input
              type="number"
              value={count}
              onChange={e => setCount(parseInt(e.target.value, 10) || 1)}
              min={1}
              max={30}
              style={{ width: 108 }}
            />
          </>
        )}{' '}
        {active ? <Button type="submit">Submit</Button> : <WalletButton />}
      </ResponsiveLayout>
    </StyledForm>
  );
};

const TierList = styled(ResponsiveLayout)`
  align-items: stretch;

  @media screen and (max-width: ${props => props.breakpoint}px) {
    align-items: center;
  }
`;

const WhitelistRegisterPage = ({ tier }) => {
  return (
    <>
      <TierList gap={20} breakpoint={1200}>
        <SilverTierCard />
        {tier > 1 && (
          <>
            <GoldTierCard />
            <PlatinumTierCard />
          </>
        )}
      </TierList>
      <WhitelistRequestForm tier={tier} />
    </>
  );
};

const WhitelistTokenErrorPage = () => {
  return (
    <>
      <p>Sorry! Your link to join the whitelist is invalid or already used.</p>
    </>
  );
};

function useTokenVerifyQuery(token) {
  return useQuery(['token-verify', token], async () => {
    const queryParam = new URLSearchParams({ token: token });
    try {
      const response = await fetch(
        `${config.backendUrl}/whitelist/verify?${queryParam}`
      );
      return await response.json();
    } catch (error) {
      console.warn('Token verification error', error);
      return false;
    }
  });
}

const StyledContent = styled(Content)`
  h1.sub {
    text-transform: none;
  }
  p {
    text-align: center;
  }
`;

const WhitelistPageInner = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const { data, isLoading } = useTokenVerifyQuery(searchParams.get('token'));

  return isLoading ? (
    <>Loading</>
  ) : data?.success ? (
    <WhitelistRegisterPage tier={data.jwtPayload.tier} />
  ) : (
    <WhitelistTokenErrorPage />
  );
};

const WhitelistPage = () => {
  return (
    <Layout>
      <Seo title="Racehorse NFT Whitelist" />
      <Section>
        <StyledContent alignItems="center" gap={40} style={{ minHeight: 800 }}>
          <AutoLayout direction="column" alignItems="center">
            <RacehorseTitle />
            <h1 className="sub">Whitelist</h1>
          </AutoLayout>
          <p className="content-regular">
            Racehorse NFT is a collection of 10,000 unique digital collections
            of NFTs, living on the Ethereum blockchain. Your racehorsenft is
            also your membership card, which grants access to local events,
            racehorse updates, and more member-only benefits. More benefits will
            be unlocked as the community walk through the roadmap.
          </p>
          <WhitelistPageInner />
        </StyledContent>
      </Section>
    </Layout>
  );
};

export default WhitelistPage;
