import styled from 'styled-components';

const Input = styled.input`
  background: none;
  border: 1px solid ${props => props.theme.palette.primary.main};
  border-radius: 5px;

  padding: 10px 20px;
  box-sizing: border-box;

  color: #caefff;
`;

export default Input;
