import Button from './Button';
import Content from './Content';
import Section from './Section';
import { AutoLayout, ResponsiveLayout } from '@digigear/elements';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled, { css } from 'styled-components';

const StyledTierCard = styled(AutoLayout)`
  border: 1px solid ${props => props.theme.palette.primary.main};
  border-radius: 5px;

  width: ${props => (props.large ? 456 : 342)}px;

  .header {
    position: relative;

    .gatsby-image-wrapper {
      width: 342px;
      height: 116px;

      ${props =>
        props.large &&
        css`
          width: 456px;
          height: 154px;
        `}
    }
    .overlay {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      padding: 0 32px;

      h2,
      .subheading {
        color: #2c2c2c;
      }

      h2 {
        font-size: 35px;
        line-height: 50px;

        .large & {
          font-size: 45px;
          line-height: 65px;
        }
      }

      .subheading {
        font-size: 24px;
        font-weight: 500;
        line-height: 34.75px;

        .large & {
          font-size: 30px;
          line-height: 43px;
        }
      }
    }
  }

  .content {
    margin: 34px 8px;
    text-align: center;
  }
`;

const TierCard = ({
  headerImage,
  headerTitle,
  headerSubTitle,
  children,
  large,
}) => {
  return (
    <StyledTierCard direction="column" large={large}>
      <div className="header">
        {headerImage}
        <AutoLayout
          direction="column"
          gap={-8}
          justifyContent="center"
          className="overlay"
        >
          <h2>{headerTitle}</h2>
          <div className="subheading">{headerSubTitle}</div>
        </AutoLayout>
      </div>
      <AutoLayout
        direction="column"
        className="content"
        justifyContent="center"
        flexGrow={1}
        flexShrink={1}
      >
        {children}
      </AutoLayout>
    </StyledTierCard>
  );
};

const TierFeatures = styled(AutoLayout).attrs({
  direction: 'column',
  alignItems: 'center',
})`
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 21px;

  // ${StyledTierCard}.large & {
  //   font-size: 20px;
  //   line-height: 27px;
  // }

  & > :not(:first-child)::before {
    content: '';
    display: block;
    width: 35.5px;
    height: 0.5px;
    background-color: rgba(255, 255, 255, 0.5);
    margin: ${props => (props.large ? '24px' : '12px')} auto;
  }
`;

const TierAnd = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  font-style: italic;

  font-size: 20px;
  line-height: 27px;
  margin-bottom: 25px;

  // ${StyledTierCard}.large & {
  //   font-size: 25px;
  //   line-height: 34px;
  //   margin-bottom: 35px;
  // }
`;

export const SilverTierCard = ({ interactive }) => {
  return (
    <TierCard
      headerImage={
        <StaticImage
          src="../images/tiers-silver-banner.png"
          alt=""
          placeholder="blurred"
          formats={['png']}
          width={342}
          height={116}
        />
      }
      headerTitle="Silver"
      headerSubTitle="1 NFT"
    >
      <TierFeatures>
        <p>Unique Digital Membership Pass</p>
        <p>Design Your Own Racing Silks</p>
        <p>Exclusive Racehorse Updates</p>
        <p>Access to Members-Only Events*</p>
        <p>Members-Only Merchandise Store</p>
      </TierFeatures>

      {interactive && (
        <div style={{ marginTop: 42 }}>
          <Button href="#mint">Mint</Button>
        </div>
      )}
    </TierCard>
  );
};

export const GoldTierCard = ({ large, interactive }) => {
  return (
    <TierCard
      headerImage={
        <StaticImage
          src="../images/tiers-gold-banner.png"
          alt=""
          formats={['png']}
          width={456}
          height={154}
        />
      }
      headerTitle="Gold"
      headerSubTitle="30 NFT"
      large={large}
    >
      <TierAnd>Everything in Silver and…</TierAnd>

      <TierFeatures large={large}>
        <p>Priority Access to Members-Only Events</p>
        <p>Access Global Partners Facilities</p>
        <p>Winning Photos with Racehorses</p>
        <p>Guaranteed whitelist slot for Next Series of RacehorseNFT</p>
      </TierFeatures>

      {interactive && (
        <div style={{ marginTop: 22 }}>
          {/*<Button href="#mint" style={{ padding: '21px 32px', fontSize: 24 }}>*/}
          <Button href="#mint">Mint</Button>
        </div>
      )}
    </TierCard>
  );
};

export const PlatinumTierCard = ({ interactive }) => {
  return (
    <TierCard
      headerImage={
        <StaticImage
          src="../images/tiers-platinum-banner.png"
          alt=""
          placeholder="blurred"
          formats={['png']}
          width={342}
          height={116}
        />
      }
      headerTitle="Platinum"
      headerSubTitle=""
    >
      <TierAnd>Everything in Gold and…</TierAnd>

      <TierFeatures>
        <p>VIP Access to Members-Only Event</p>
        <p>VIP Access to Global Partners facilities</p>
        <p>Priority Winning Photos with Racehorses</p>
        <p>Special IRON RacehorseNFT Merchandise</p>
      </TierFeatures>

      {interactive && (
        <div style={{ marginTop: 42 }}>
          <Button disabled>By Invitation Only</Button>
        </div>
      )}
    </TierCard>
  );
};

const TierLayout = styled(ResponsiveLayout)`
  align-items: stretch;

  @media screen and (max-width: ${props => props.breakpoint}px) {
    align-items: center;
  }
`;

const StyledContent = styled(Content)``;

const StyledTiersSection = styled(Section)``;

const TiersSection = () => {
  return (
    <StyledTiersSection>
      <StyledContent gap={12}>
        <h1>Tiers</h1>
        <TierLayout gap={30} vertical={{ gap: 40 }} breakpoint={1200}>
          <SilverTierCard interactive />
          <GoldTierCard interactive />
          <PlatinumTierCard interactive />
        </TierLayout>
      </StyledContent>
    </StyledTiersSection>
  );
};

export default TiersSection;
